<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title"><i class="ri-file-chart-fill font-size-20"></i>&nbsp;&nbsp;{{cvCardTitle}} Results</h4>
            <h5 class="card-title primary-color">
              {{cvSubjectModuleName}}<button class="actionIcon btn primary-color" title="Take Assessment" @click="goToAppDetail(appId)" v-if="appId"><i class="ri-question-answer-fill font-size-20"></i></button>
            </h5>
            <p style="color:#e5252c;" v-if="userDetails">
              <span>Name:&nbsp;{{userDetails.user_name}}</span><br>
              <span>Email:&nbsp;{{userDetails.user_email}}</span><br>
              <span v-if="userDetails.user_mobile">Mobile:&nbsp;{{userDetails.user_mobile}}</span>
            </p>
          </template>
          <template v-slot:body>
            <b-row class="row w-100">
              <b-col sm="4" md="4" lg="4" xl="4" class="mb-3">
                <b-form-input
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="resultList && resultList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="resultList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage"
                >
                <template v-slot:cell(created_on)="data">
                  {{data.item.created_on | dateFormat}}
                </template>

                <template v-slot:cell(aprs_percentage)="data">
                  {{data.item.aprs_percentage}}%
                </template>

                <template v-slot:cell(action)="data">
                  <i title="View attempt result" class="ri-file-chart-fill primary-color pointer font-size-20" @click="goToAttemptResultView(data.item)"></i>
                  <i title="View attempt analysis" class="m-4 ri-search-eye-line primary-color pointer font-size-20" @click="goToAttemptAnsalysis(data.item)"></i>
                </template>

                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1"></b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    </b-container>
    </template>
<script>
import { TPrepResults } from "../../../FackApi/api/TPrepResults"
import { socialvue } from "../../../config/pluginInit"
import userPermission from "../../../Utils/user_permission"

export default {
  name: "TPrepResultsList",
  data () {
    return {
      apiName: "tprep_results_list",
      cvCardTitle: "TestSeries",
      cvSubjectModuleName: "",
      appId: null,
      resultList: [],
      cvSearchText: "Type to Search",
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      sortBy: "name",
      sortDesc: false,
      userDetails: null,
      columns: [
        { label: "Attempt", key: "id", class: "text-left align-text-top", sortable: true },
        { label: "Your Score", key: "aprs_score", class: "text-left align-text-top", sortable: true },
        { label: "Total Score", key: "aprs_total_score", class: "text-left align-text-top", sortable: true },
        { label: "Percentage %", key: "aprs_percentage", class: "text-left align-text-top", sortable: true },
        { label: "Quiz Played On", key: "created_on", class: "text-left align-text-top", sortable: true },
        { label: "Analyse", key: "action", class: "text-left align-text-top", sortable: true }
      ]
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  async beforeMount () {
    // Auth Access for Current User Role
    if (this.$route.query.gide_user_id && this.$route.query.gide_user_id != this.userData.user_id) {
      console.log("Checking permissions!!")
      if (!userPermission(this, this.userData, this.apiName)) {
        this.$router.back()
      }
    }
  },
  mounted () {
    socialvue.index()
    this.appId = this.$route.params.appId
    this.getAllResults()
  },
  methods: {
    /**
     * goTo Quiz/App Details
     */
    goToAppDetail (appId) {
      if (appId) {
        this.$router.push(`/quiz_detail/${appId}`)
      }
    },
    /**
     * getAllResults
     */
    async getAllResults () {
      const subjectModuleObj = {
        app_id: this.appId
      }
      if (this.$route.query.gide_user_id) {
        subjectModuleObj.gide_user_id = this.$route.query.gide_user_id
      }
      const resultList = await TPrepResults.tprepResultsList(this, subjectModuleObj)
      if (resultList.resp_status) {
        this.resultList = resultList.resp_data.data
        // Adding serial number
        this.resultList.forEach((e, i) => {
          e.action = null
          e.id = i + 1
        })

        if (this.resultList[0].subject_name) {
          this.cvCardTitle += `: ${this.resultList[0].subject_name}`
        }

        if (this.resultList[0].subject_name) {
          this.cvSubjectModuleName = `${this.resultList[0].subject_module_name}`
        }
        this.userDetails = resultList.user_details
      }
    },
    /**
     * goToAttemptResultView
     */
    goToAttemptResultView (attemptObj) {
      let url = `/app_result_view_all/${attemptObj.app_id}/${attemptObj.user_id}/${attemptObj.attempt}/1`
      this.$router.push(url)
    },
    /**
     * goToAttemptAnsalysis
     */
    goToAttemptAnsalysis (attemptData) {
      let url = `/analyse_app_result/${attemptData.app_id}/${attemptData.user_id}?attempt=${attemptData.attempt}`
      this.$router.push(url)
    }
  }
}
</script>
